<template>
  <div class="align-items-center">
    {{ typeof value === "string" ? value : value?.label }}
    <button
      class="btn btn-secondary btn-sm"
      v-on:click="showModal()"
      v-if="editable"
    >
      <BaseIcon name="edit-sqare" />
    </button>
  </div>
  <ExternalCategoriesModal
    ref="externalModal"
    :model-value="value"
    :type="row"
    v-on:change="change"
    :id="id"
  />
</template>
<script>
import BaseIcon from "@/components/icons/BaseIcon";
import ExternalCategoriesModal from "../../modals/ExternalCategoriesModal.vue";
export default {
  name: "ExternalCategoryPicker",
  components: { ExternalCategoriesModal, BaseIcon },
  props: {
    id: Number,
    row: Object,
    modelValue: Object,
    editable: Boolean,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    change(data) {
      let e = {
        id: data.id,
        label: data.label,
      };
      this.value = e;
      this.$emit("update", e, false);
      this.$emit("update:modelValue", e);
    },
    showModal() {
      this.$refs.externalModal.showModal();
    },
  },
  emits: ["update:modelValue", "update"],
};
</script>
