<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ type?.name }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="categories === null">
            <Vue3Lottie :animationData="iconJSON" :height="160" :width="300" />
          </div>
          <div v-else class="custom-input whitebg-input">
            <input
              type="search"
              class="form-control mb-4"
              v-model="q"
              :placeholder="$t(`externalCategoriesModal.searchItem`)"
            />
            <Tree
              v-model:nodes="categories"
              :showChildCount="true"
              :useCheckbox="true"
              v-model:searchText="q"
              v-on:nodeClick="selectedItem"
            >
              <template
                #checkbox="{ id, checked, node, indeterminate, toggleCheckbox }"
              >
                <multiple-checkbox
                  :disabled="node.nodes?.length > 0"
                  :model-value="checked ? 2 : indeterminate ? 1 : 0"
                  type="radio"
                  :value="node?.id"
                  :name="'selectItems_' + type?.id + '_' + id"
                  v-on:change="
                    () => {
                      toggle = true;
                      toggleCheckbox();
                    }
                  "
                />
              </template>
            </Tree>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
import loadFileJSON from "../../assets/animations/loadFile.json";
import http from "../../modules/http";
const bootstrap = require("bootstrap");
import Tree from "vue3-tree";
import MultipleCheckbox from "../lists/listItems/MultipleCheckbox.vue";

export default {
  name: "ExternalCategoriesModal",
  components: { MultipleCheckbox, Vue3Lottie, Tree },
  props: {
    modelValue: Object,
    type: Object,
    id: Number,
  },
  data() {
    return {
      value: this.modelValue,
      categories: null,
      iconJSON: loadFileJSON,
      q: "",
      toggle: false,
    };
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
      if (this.categories === null) {
        this.loadCategories();
      }
    },
    selectedItem(e) {
      if (this.toggle) {
        this.toggle = false;
        if (e.checked && this.value !== null && this.value !== undefined) {
          this.value.checked = false;
        }
        this.value = e;
        this.$emit("update:modelValue", e);
        this.$emit("change", e);
      }
    },
    loadCategories() {
      http.fetch("/external/categories/" + this.type.id).then((data) => {
        this.categories = data.data;
        if (typeof this.value === "string") {
          this.value = null;
        }
      });
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
