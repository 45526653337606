<template>
  <label class="cstm-check" :class="{ waiting: modelValue === 1 }">
    <input
      :type="type"
      :value="value"
      :name="name?.length > 0 ? name : ''"
      :checked="modelValue === 2 || modelValue === true"
      :disabled="disabled"
      v-on:change="handleChange"
    />
    <span class="checkmark radio"></span>
  </label>
</template>

<script>
export default {
  name: "MultipleCheckbox",
  props: {
    id: Number,
    type: {
      type: String,
      default: "checkbox",
    },
    name: String,
    row: Object,
    modelValue: Object,
    disabled: {
      type: Boolean,
      default: true,
    },
    value: {
      default: true,
    },
  },
  emits: ["update:modelValue", "change"],
  methods: {
    handleChange(e) {
      this.$emit("update:modelValue", e);
      this.$emit("change", e);
    },
  },
};
</script>
